<template>
  <a
    :href="model.url || model.link.url"
    class="c-product-card"
  >
    <div class="c-product-card__media">
      <Picture
        v-if="model.image"
        v-bind="model.image"
        class="c-product-card__media__image"
      />
      <add-to-favorites
        :model="model.buyButton"
        :placement="placement"
        type="product"
      />
    </div>
    <div class="c-card__content">
      <div class="c-card__text">
        <span class="e-preamble">
          {{ model.subHeading || model.subTitle }}
        </span>
        <h3>{{ (model.heading || model.title).replace('&nbsp;', ' ') }}</h3>
      </div>
      <div
        v-if="model.buyButton"
        class="c-product-card__buy-controls"
      >
        <BBWButton
          :model="model.buyButton"
          :placement="placement"
        >
          <span>{{ buyButtonLabel }}</span>
        </BBWButton>
      </div>
    </div>
  </a>
</template>

<script>
import BBWButton from '@/components/BBWButton.vue'
import Picture from '@/components/Picture.vue'
import AddToFavorites from '@/components/AddToFavorites.vue'

export default {
  components: {
    BBWButton,
    Picture,
    AddToFavorites
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    buyButtonLabel: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      required: true
    }
  }
}
</script>
